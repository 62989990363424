<template>
    <div>
        <h4>个人主页 <b-badge variant="info">{{ totalCases }}</b-badge></h4>
        <hr>

        <!-- 控制面板 -->
        <b-card class="mb-3">
            <b-row>
                <b-col md="3" sm="6" class="mb-2">
                    <label class="d-block">案例类型</label>
                    <b-form-select v-model="selectedType" :options="caseTypes" size="sm"></b-form-select>
                </b-col>
                <b-col md="3" sm="6" class="mb-2">
                    <label class="d-block">排序方式</label>
                    <b-form-select v-model="order" :options="orderOptions" size="sm"></b-form-select>
                </b-col>
                <b-col md="6" class="mb-2">
                    <label class="d-block">时间范围</label>
                    <b-row>
                        <b-col>
                            <b-form-datepicker v-model="startDate" size="sm" placeholder="起始日期"></b-form-datepicker>
                        </b-col>
                        <b-col>
                            <b-form-datepicker v-model="endDate" size="sm" placeholder="结束日期"></b-form-datepicker>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col cols="auto">
                    <b-button size="sm" variant="primary" @click="fetchData">查询</b-button>
                    <b-button size="sm" variant="outline-secondary" class="ml-2" @click="resetFilters">重置</b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-overlay :show="isLoading" rounded="sm">
            <!-- 案例列表 -->
            <div v-if="cases.length > 0">
                <div class="timeline">
                    <div v-for="(item, index) in cases" :key="index" class="timeline-item" :class="getCaseTypeClass(item)">
                        <div class="timeline-badge">
                            <i :class="getCaseTypeIcon(item)"></i>
                        </div>
                        <div class="timeline-card" @click="goToCase(item.case_id)">
                            <div class="timeline-time">
                                {{ formatDate(item.last_mod_time) }}
                            </div>
                            <div class="status-badge" :class="'bg-' + getStatusVariant(item.current_status)">
                                {{ getStatusTitle(item.current_status) }}
                            </div>
                            <div class="case-title">
                                案件 #{{ item.case_id }} - {{ item.target_current_name }}
                            </div>
                            <div class="case-type">
                                {{ getCaseTypeLabel(item) }}
                            </div>
                        </div>
                    </div>
                </div>
                
                <b-row class="mt-3">
                    <b-col>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalItems"
                            :per-page="perPage"
                            align="center"
                            class="my-0"
                            @change="handlePageChange"
                            v-if="totalItems > perPage"
                        ></b-pagination>
                    </b-col>
                </b-row>
            </div>
            
            <!-- 空状态 -->
            <div v-else-if="!isLoading" class="text-center my-5">
                <h5>暂无案例记录</h5>
                <p class="text-muted">您还没有参与任何案例</p>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import { request } from "@/api";

export default {
    name: "UserProfile",
    data() {
        return {
            // 查询参数
            selectedType: 'all',
            startDate: null,
            endDate: null,
            order: '-last_mod_time',
            perPage: 10,
            currentPage: 1,
            
            // 数据
            reportCases: [],
            replyCases: [],
            judgeCases: [],
            cases: [],
            totalCases: 0,
            totalItems: 0,
            isLoading: false,
            
            // 状态映射
            caseStatusName: {
                "1": {"variant": "danger", "title": "封禁"},
                "2": {"variant": "secondary", "title": "无效举报"},
                "3": {"variant": "success", "title": "自证通过"},
                "4": {"variant": "success", "title": "等待自证完成"},
                "5": {"variant": "secondary", "title": "刷枪"},
                "0": {"variant": "info", "title": "未处理"},
            },
            
            // 类型选项
            caseTypes: [
                { value: 'all', text: '所有案例' },
                { value: 'report', text: '我的举报' },
                { value: 'reply', text: '我的回复' },
                { value: 'judge', text: '我的审判' }
            ],
            
            // 排序选项
            orderOptions: [
                { value: '-last_mod_time', text: '最近更新' },
                { value: 'last_mod_time', text: '最早更新' },
                { value: '-case_id', text: '案件ID降序' },
                { value: 'case_id', text: '案件ID升序' }
            ]
        };
    },
    methods: {
        // 获取数据
        fetchData() {
            this.isLoading = true;
            
            if (this.selectedType === 'all') {
                // 获取所有三种类型的案例
                Promise.all([
                    this.fetchCasesByType('report', 30),
                    this.fetchCasesByType('reply', 30),
                    this.fetchCasesByType('judge', 30)
                ]).then(([reportData, replyData, judgeData]) => {
                    this.reportCases = reportData.data || [];
                    this.replyCases = replyData.data || [];
                    this.judgeCases = judgeData.data || [];
                    
                    // 给每个案例添加类型标记
                    this.reportCases.forEach(c => c._type = 'report');
                    this.replyCases.forEach(c => c._type = 'reply');
                    this.judgeCases.forEach(c => c._type = 'judge');
                    
                    // 合并所有案例并排序
                    const allCases = [
                        ...this.reportCases,
                        ...this.replyCases,
                        ...this.judgeCases
                    ];
                    
                    // 根据排序参数进行排序
                    this.sortCases(allCases);
                    
                    // 更新总数和当前页面数据
                    this.totalCases = allCases.length;
                    this.totalItems = allCases.length;
                    
                    // 分页显示
                    const startIdx = (this.currentPage - 1) * this.perPage;
                    const endIdx = startIdx + this.perPage;
                    this.cases = allCases.slice(startIdx, endIdx);
                    
                    this.isLoading = false;
                }).catch(error => {
                    console.error("获取案例失败", error);
                    this.isLoading = false;
                });
            } else {
                // 获取单一类型的案例
                this.fetchCasesByType(this.selectedType, this.perPage)
                    .then(response => {
                        const data = response.data || [];
                        data.forEach(c => c._type = this.selectedType);
                        
                        this.cases = data;
                        this.totalItems = response.num || 0;
                        this.totalCases = response.num || 0;
                        
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.error(`获取${this.selectedType}案例失败`, error);
                        this.isLoading = false;
                    });
            }
        },
        
        // 根据类型获取案例
        fetchCasesByType(type, size) {
            const params = {
                pn: this.currentPage,
                size: size,
                order: this.order
            };
            
            // 添加时间范围参数
            if (this.startDate) {
                params.start_date = Math.floor(new Date(this.startDate).getTime() / 1000);
            }
            
            if (this.endDate) {
                // 设置为当天结束时间
                const endDateTime = new Date(this.endDate);
                endDateTime.setHours(23, 59, 59);
                params.end_date = Math.floor(endDateTime.getTime() / 1000);
            }
            
            const queryString = Object.keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&');
            
            // 根据类型选择API路径
            let apiPath = '';
            switch(type) {
                case 'report':
                    apiPath = '/user/self/get_report_cases';
                    break;
                case 'reply':
                    apiPath = '/user/self/get_reply_cases';
                    break;
                case 'judge':
                    apiPath = '/user/self/get_judge_cases';
                    break;
            }
            
            return request('GET', `${apiPath}?${queryString}`);
        },
        
        // 根据排序参数对案例进行排序
        sortCases(cases) {
            const field = this.order.startsWith('-') ? this.order.substring(1) : this.order;
            const isDesc = this.order.startsWith('-');
            
            cases.sort((a, b) => {
                let valueA, valueB;
                
                if (field === 'case_id') {
                    valueA = a.case_id;
                    valueB = b.case_id;
                } else if (field === 'last_mod_time') {
                    valueA = new Date(a.last_mod_time).getTime();
                    valueB = new Date(b.last_mod_time).getTime();
                }
                
                if (isDesc) {
                    return valueB - valueA;
                } else {
                    return valueA - valueB;
                }
            });
        },
        
        // 处理页面变更
        handlePageChange(page) {
            this.currentPage = page;
            this.fetchData();
        },
        
        // 重置所有筛选条件
        resetFilters() {
            this.selectedType = 'all';
            this.startDate = null;
            this.endDate = null;
            this.order = '-last_mod_time';
            this.currentPage = 1;
            this.fetchData();
        },
        
        // 获取状态变体
        getStatusVariant(status) {
            const statusKey = status.toString();
            return this.caseStatusName[statusKey] ? this.caseStatusName[statusKey].variant : 'secondary';
        },
        
        // 获取状态标题
        getStatusTitle(status) {
            const statusKey = status.toString();
            return this.caseStatusName[statusKey] ? this.caseStatusName[statusKey].title : '未知';
        },
        
        // 格式化日期
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString('zh-CN', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            });
        },
        
        // 获取案例类型的图标
        getCaseTypeIcon(item) {
            switch(item._type) {
                case 'report': return 'fas fa-exclamation-circle';
                case 'reply': return 'fas fa-reply';
                case 'judge': return 'fas fa-gavel';
                default: return 'fas fa-file';
            }
        },
        
        // 获取案例类型的类名
        getCaseTypeClass(item) {
            switch(item._type) {
                case 'report': return 'type-report';
                case 'reply': return 'type-reply';
                case 'judge': return 'type-judge';
                default: return '';
            }
        },
        
        // 获取案例类型的标签文本
        getCaseTypeLabel(item) {
            switch(item._type) {
                case 'report': return '我的举报';
                case 'reply': return '我的回复';
                case 'judge': return '我的审判';
                default: return '未知类型';
            }
        },
        
        // 跳转到案例详情
        goToCase(caseId) {
            this.$router.push('/case/' + caseId);
        }
    },
    mounted() {
        // 初始加载
        this.fetchData();
    }
};
</script>

<style scoped>
.timeline {
    position: relative;
    padding: 20px 0;
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    width: 3px;
    background: #e9ecef;
}

.timeline-item {
    position: relative;
    margin-bottom: 25px;
}

.timeline-badge {
    position: absolute;
    left: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.type-report .timeline-badge {
    background-color: #dc3545;
}

.type-reply .timeline-badge {
    background-color: #17a2b8;
}

.type-judge .timeline-badge {
    background-color: #6f42c1;
}

.timeline-card {
    position: relative;
    margin-left: 50px;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

.timeline-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.timeline-time {
    color: #6c757d;
    font-size: 0.85em;
    margin-bottom: 8px;
}

.case-title {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 5px;
}

.case-type {
    font-size: 0.85em;
    color: #6c757d;
}

.status-badge {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 0.8em;
    color: white;
    font-weight: bold;
}

.bg-danger {
    background-color: #dc3545;
}

.bg-success {
    background-color: #28a745;
}

.bg-info {
    background-color: #17a2b8;
}

.bg-secondary {
    background-color: #6c757d;
}

.bg-primary {
    background-color: #007bff;
}

.bg-warning {
    background-color: #ffc107;
}

/* 响应式布局 */
@media (min-width: 768px) {
    .timeline:before {
        left: 50%;
        margin-left: -1.5px;
    }
    
    .timeline-item {
        display: flex;
        align-items: center;
    }
    
    .timeline-badge {
        left: 50%;
        margin-left: -12px;
    }
    
    .timeline-card {
        width: 45%;
        margin-left: 0;
    }
    
    .timeline-item:nth-child(odd) {
        justify-content: flex-end;
    }
    
    .timeline-item:nth-child(odd) .timeline-card {
        margin-right: 50px;
    }
    
    .timeline-item:nth-child(even) {
        flex-direction: row-reverse;
    }
    
    .timeline-item:nth-child(even) .timeline-card {
        margin-left: 50px;
    }
}

@media (max-width: 767px) {
    .timeline-item {
        margin-bottom: 20px;
    }
}
</style> 