import Vue from 'vue'
import Router from 'vue-router'
//fontawesome import 'font-awesome/css/font-awesome.min.css'

import IndexPage from '@/components/IndexPage'
import PageCaseDetails from '@/components/PageCaseDetails'
import PageCaseList from '@/components/PageCaseList'
import PageAppeal from '@/components/PageAppeal'
import HelloWorld from "@/components/HelloWorld";
import AboutUs from "@/components/AboutUs"; //关于

import SubUserList from "@/components/AdminComponents/SubUserList";
import SubUserRegister from "@/components/AdminComponents/SubUserRegister";
import SubUserLogs from "@/components/AdminComponents/SubUserLogs";
import PageRegister from "@/components/PageRegister";
import PageUser from "@/components/PageUser";
import UserNotify from "@/components/UserComponents/UserNotify";
import UserFavour from "@/components/UserComponents/UserFavour";
import UserProfile from "@/components/UserComponents/UserProfile";
import PageWaterFall from "@/components/PageWaterFall";
import PageForgottenPassword from "@/components/PageForgottenPassword.vue";

Vue.use(Router)

export default new Router({
    mode: "hash",
    routes: [
        {
            path: '/',
            name: 'index',
            component: IndexPage
        },
        {
            path: '/about',
            name: 'about',
            component: AboutUs
        },
        {
            path: '/appeal',
            name: 'appeal',
            component: PageAppeal
        },
        {
            path: '/register',
            name: 'register',
            component: PageRegister
        },
        {
            path: '/case/:id/:reply_id?',
            name: 'case',
            component: PageCaseDetails,
        },
        {
            path: '/list',
            name: 'page_case_list',
            component: PageCaseList
        },
        {
            path: '/admin',
            name: 'admin',
            component: HelloWorld,
            children: [
                {
                    path: "user_list",
                    component: SubUserList
                },
                {
                    path: "user_register",
                    component: SubUserRegister
                },
                {
                    path: "user_logs/:id",
                    component: SubUserLogs
                }
            ]
        },
        {
            path: "/user",
            name: "user",
            component: PageUser,
            children: [
                {
                    path: "notify",
                    component: UserNotify
                },
                {
                    path: "favour",
                    component: UserFavour
                },
                {
                    path: "profile",
                    component: UserProfile
                }
            ]
        },
        {
            path: '/waterfall',
            name: "PageWaterFall",
            component: PageWaterFall
        },
        {
            path: '/forgotten_password',
            name: "PageForgottenPassword",
            component: PageForgottenPassword
        }
    ]
})
